// Bootstrap overrides for this template
.bg-primary {
    // background: $theme-primary;
    // background: -webkit-linear-gradient($theme-primary, darken($theme-primary, 5%));
    // background: linear-gradient(#c70000, darken($theme-primary, 5%));
    background-color: #730c0c !important;
}

.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

