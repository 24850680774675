// Styling for the footer
footer {
  padding: 25px 0;
  text-align: center;
  background-color: $gray-darker;
  color: white;
  p {
    margin: 0;
  }
  ul {
    margin-bottom: 0;
    li {
      a {
        font-size: 1.2em;
        color: white;
        &:hover,
        &:focus,
        &:active,
        &.active {
            text-decoration: none;
        }
      }
    }
  }

  ul.list-inline {
    li {
      display: inline;
      margin: 0 5px;
      a.btn-social {
        display: inline-block;
        width: 50px;
        height: 50px;
        border: 2px solid #fff;
        border-radius: 100%;
        text-align: center;
        font-size: 20px;
          line-height: 45px;
      }
    }
  }
}

.footer-col {
  padding-top: 20px;
  @media(min-width: 992px) {
    padding-top: 10px;
  }
}

footer h3 {
  margin-bottom: 25px;
}

footer img.footer-logo {
  width: 240px;
}

/*footer*/
ul.tools {
  margin-top: 0;
}
ul.tools li {
  font-size: 1em;
}

