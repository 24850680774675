.masthead {
  h1 {
    font-size: 2.2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.7em;
    line-height: 1.3em;
    padding-bottom: 20px;
  }
}

.homepage-logo {
  width: 60%;
  margin: auto;
  text-align: center;
  display: block;
  padding-bottom: 30px;

  @media (min-width: 575px) {
    width: 100%;
    position: absolute;
    top: 50%;
    max-width: none;
    margin: 0;
    transform: translateY(-50%);
  }
}

ol.overview-stages li {
  padding: 10px 0;
}

section#main {
  text-align: center;
  color: #fff;
  background: $site-color-primary;
}

section#main .container {
  padding-top: 60px;
  padding-bottom: 50px;
}

section#main img {
  display: block;
  margin: 0 auto 20px;
}

section#main .intro-text .name {
  display: block;
  text-transform: uppercase;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

section#main .intro-text .skills {
  font-size: 1.25em;
  font-weight: 300;
}

@media(min-width:768px) {
  section#main {
    text-align: left;
  }

  section#main .container {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  section#main .intro-text .name {
    font-size: 4.75em;
  }

  section#main .intro-text .skills {
    font-size: 1.75em;
  }
}

.content-body {
  text-align: left;
  margin-top: 30px;

  @media (min-width: 992px) {
    margin-top: 35px;
  }
}

.reference-nav {
  margin-top: 30px;
  margin-bottom: -10px;
}

footer h3 {
  margin-bottom: 25px;
}

footer img.footer-logo {
  width: 240px;
}


.edit-on-github {
  font-size: 0.8em;
  border-top: thin solid white;
  border-bottom: thin solid white;
  padding: 20px;
  margin-top: 60px;

  h2 {
    font-size: 1.3em;
  }

  li {
    font-size: 0.9em;
  }
}

.use-cases {
  font-size: 1.25em;
}

.use-cases li {
  margin-bottom: 15px;
}


.content-body-list {

  .video-container iframe,
  .video-container object,
  .video-container embed {
    width: 180px;

    @media (min-width: 768px) {
      /* 16:9 ratio */
      width: 300px;
      height: 169px;
    }
  }
}

#timeline {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.learning-content {
  padding-top: 30px;
}

.article-box {
  padding-top: 20px;
}

.related-body {
  font-family: "Calibre", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.15em;
  padding-bottom: 10px;
  position: relative;

  // background-color: yellow;
  // border: 1px solid red;
  @media (min-width: 1000px) {
    margin-top: 0;
    padding-bottom: 30px;
    height: 230px;
  }

  .related-thumbnail {
    float: left;
    width: 110px;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    height: 70px;

    // background-color: green;
    @media (min-width: 768px) {
      width: 150px;
      height: 120px;
    }

    @media (min-width: 1000px) {
      width: inherit;
      height: 160px;
      text-align: center;
      // background-color: orange;
      display: block;
      float: none;
    }

    img.center-crop {
      object-fit: contain;
      object-position: center;
      /* Center the image within the element */
      height: 64px;

      @media (min-width: 768px) {
        height: 110px;
      }

      @media (min-width: 1000px) {
        height: 160px;
      }
    }
  }

  .related-link {
    // background-color: red;
    vertical-align: top;
    display: table-cell;
    height: 70px;
    padding-left: 15px;

    @media (min-width: 1000px) {
      height: 50px;
      display: block;
      clear: both;
      padding-top: 10px;
      margin-bottom: 5px;
      text-align: center;
    }

    a {
      display: block;
      vertical-align: middle;
      line-height: normal;
    }
  }
}

.related-body:after {
  content: "";
  display: table;
  clear: both;
}

.related-filler {
  @media (min-width: 1000px) {
    padding: 30px;
  }
}

blockquote {
  padding: 12px 24px;
  margin: 0 0 24px;
  border-left: 5px solid #eee;
}

ul.toc {
  list-style-type: none;
  padding: 5px;
  margin-left: 0;

  li {
    list-style-type: none;
    padding-left: 5px;
    margin-left: 0;
  }

  ul {
    padding-left: 10px;
  }
}

.getting-started-options {
  padding: 40px 30px;
  text-align: center;

  img {
    vertical-align: middle;
    text-align: center;
    padding: 20px;
  }

  font-size: 2em;
}

#content .container-fluid img {
  @media (max-width: 768px) {
    max-width: 100%;
  }
}

// https://coolestguidesontheplanet.com/videodrome/youtube/
// I added another box wrapper to control the width
.video-box {
  max-width: 600px;
  text-align: left;
  margin: 0 auto 0 0;
  padding-bottom: 20px;

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.quick-start-video {
  .video-desc {
    h4 {
      font-weight: bold;
    }

    // background-color: red;
    font-size: 1.25em;
    vertical-align: middle;
    display: table-cell;

    @media (min-width: 768px) {
      height: 350px;
    }
  }
}

#search-field {
  // background-color: purple;

  td {
    padding: 0;
    margin: 0;
    border: none;
  }

  table {
    // background-color: orange;
    border: none;
    margin: 0;
  }

  .gsc-input-box td {
    vertical-align: top;
    // padding-top: 15px;
  }
}

.search-result {
  padding-bottom: 24px;
}

.search-tips {
  border: 1px solid gray;
  // background-color: red;
  font-size: 0.9em;
  padding: 10px;
  margin-bottom: 15px;

  p {
    margin: 0;
  }
}

.search-terms {
  background-color: #ffffcb;
  padding: 0.25em;
}

.search-count {
  font-weight: bold;
}


.top-bar {
  .has-search {
    // mobile
    // without padding-left its centered
    margin: 0;
    padding: 0;
    width: 200px;

    // desktop
    @media (min-width: 500px) {
      // without padding-left its centered
      width: 320px;
    }

    @media (min-width: 600px) {
      // without padding-left its centered
      width: 400px;
    }

    @media (min-width: 768px) {
      padding-left: 64px;
      width: 512px;
    }


    .form-control-feedback {
      position: absolute;
      z-index: 2;
      width: 2.375rem;
      height: 2.375rem;
      line-height: 2.375rem;
      text-align: center;
      pointer-events: none;
      color: #aaa;
    }

    .form-control {
      padding-left: 2.375rem;
      width: 100%;
      display: inline;
    }

    input {
      width: auto;
    }
  }
}

.navbar-logo {
  max-height: 25px;

  @media (min-width: 992px) {
    max-height: 55px;
  }
}

.navbar-brand {
  margin: 0;
  padding: 0;
}

#content img {
  max-width: 800px;
}

.sponsors {
  background-color: white !important;

  h2 {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
}

#timeline {
  background-color: #f6f6f6 !important;
}

.learn {
  margin-bottom: 20px;
}

.splash-links {
  a {
    color: #a8d7ff;
  }
}

/* Ensure code blocks take full width and are not restricted */
.highlight {
  position: relative;
  width: 100%;
  padding-right: 50px;
  /* Add some padding to the right to make space for the button */
}

/* Style the copy button */
.clipboard-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #7a8087;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

/* Hover effect for the copy button */
.clipboard-button:hover {
  opacity: 1;
}